










import { Component, Vue } from 'vue-property-decorator';
import PublicHeader from './Header.vue';
import PublicView from './View.vue';
import Footer from '../common/Footer.vue';

@Component({
  components: {
    PublicHeader,
    PublicView,
    Footer,
  },
})
export default class PublicIndex extends Vue {
}
